.container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.text {
  justify-content: center;
  align-items: center;
}
.image {
  border-radius: 5px;
  border: 5px solid rgb(10, 8, 20);
}
