.container {
  background-color: var(--pro-secondary);
  border-radius: 5px;
  padding: 1rem 10px 4px;
  margin: 15px 0;
  position: relative;
}

.corner_button {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  width: 12px;
  height: 12px;
  padding: 0;
  --pro-btn-close-opacity: 0.4;
  z-index: 2;
}
