.logo_link{
    figure{
        max-width: 150px;
        img{
            width: 100%;
        }
    }
}

.overflow_wrap{
    max-width: 100%;
    margin-inline: auto;
    overflow-x: auto;
}