.root {
  width: 100%;
  > div {
    flex: none;
  }
  &_list {
    background-color: var(--pro-secondary);
    padding: 0 0.45rem 1.3rem;
    // max-width: 170px;
    width: 100%;
    border-radius: 5px;
    & + .root_list {
      margin-left: 13px;
    }
    &_item {
      width: 100%;
      min-height: 117.89px;
      & ~ .root_list_item {
        margin-top: 0.65rem;
      }
    }
  }
}
.root_list.dashboard_list {
  max-width: 250px;
  .dashboard_card {
    width: 100%;
    > h6,
    > p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }
  }
}
.root_list {
  padding: 1.3rem 0 1.3rem;
  > h6 {
    padding-inline: 11px;
  }
  .root_list_item {
    padding-inline: 0.45rem;
    &.scroll_list {
      height: calc(100% - 20px);
      padding-bottom: 10px;
      overflow-y: auto;
    }
  }
  &.dashboard_list {
    padding: 0 0 1.3rem;
  }
}
.spinnerContainer {
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0000001a;
    z-index: 9;
    .loader {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        display: block;
        margin: 15px auto;
        position: relative;
        color: var(--pro-light);
        box-sizing: border-box;
        animation: shadowRolling 2s linear infinite;
    }
}

.card_wrapper{
  position: relative;
  .checkbox{
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;
    width: auto;
    .input{
      background-size: 15px;
      width: 15px;
      height: 15px;
    }
  }
}


@keyframes shadowRolling {
  0% {
    box-shadow: 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0),
      0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
  }
  12% {
    box-shadow: 100px 0 #1394e5, 0px 0 rgba(255, 255, 255, 0),
      0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
  }
  25% {
    box-shadow: 110px 0 #1394e5, 100px 0 #1394e5, 0px 0 rgba(255, 255, 255, 0),
      0px 0 rgba(255, 255, 255, 0);
  }
  36% {
    box-shadow: 120px 0 #1394e5, 110px 0 #1394e5, 100px 0 #1394e5,
      0px 0 rgba(255, 255, 255, 0);
  }
  50% {
    box-shadow: 130px 0 #1394e5, 120px 0 #1394e5, 110px 0 #1394e5,
      100px 0 #1394e5;
  }
  62% {
    box-shadow: 200px 0 rgba(255, 255, 255, 0), 130px 0 #1394e5, 120px 0 #1394e5,
      110px 0 #1394e5;
  }
  75% {
    box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0),
      130px 0 #1394e5, 120px 0 #1394e5;
  }
  87% {
    box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0),
      200px 0 rgba(255, 255, 255, 0), 130px 0 #1394e5;
  }
  100% {
    box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0),
      200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0);
  }
}
