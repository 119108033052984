.filter_btn {
  height: 100%;
  align-items: center;
}
.pdf_container {
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 0;
  width: 0;
}

.container {
  background-color: var(--pro-secondary);
  border-radius: 5px;
  padding: 1rem 10px 4px;
  margin: 15px 0;
  position: relative;
}


.main_container {
  &_inner {
    width: 100%;
  }
  :global {
    .nav {
      // padding-inline: 1.25rem; //padding chnages to margin
      margin-bottom: 10px;
      flex-wrap: nowrap;
      background-color: var(--pro-light);
      z-index: 9;
      // border-bottom: 0; //removed the border of nav
      .nav-link {
        white-space: pre;
        .pro-btn-link {
          --pro-button-hover-bg: var(--pro-primary-hover-bg);
          transition: none;
          &:not(.active) {
            border: 1px solid transparent;
          }
          &.active {
            color: var(--pro-primary);
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            &::after {
              background-color: var(--pro-primary);
            }
          }
          &:not(.active):active {
            background-color: var(--pro-button-hover-bg);
          }
        }
      }
    }
  }
}

.print {
  position: fixed;
  height: 0;
  width: 0;
  visibility: hidden;
}

