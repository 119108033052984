.root{
    width: 100%;
    >div{
        flex: none;
    }
    &_list{
        position: relative;
        background-color: var(--pro-secondary);
        // padding: 1.3rem 0.45rem;
        // max-width: 170px;
        width: 100%;
        border-radius: 5px;
        & + .root_list{
            margin-left: 13px;
        }
        &_item {
            width: 100%;
            min-height: 102px;
            & ~ .root_list_item{
                margin-top: 0.65rem;
            }
        }       
    }
}

.footer_btn{
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
}


.root_list{
    padding: 1.3rem 0;
    >h6{
        padding-inline: 11px;
    }
    .root_list_item{
        height: calc(100% - 20px);
        padding-bottom: 10px;
        overflow-y: auto;
        padding-inline: 11px;
    }
}