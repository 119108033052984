.root {
  :global(.dropdown) {
    width: auto;
    display: flex;
    button {
      background: none;
      border: none;
      &::after {
        display: none;
      }
    }
  }
}
.icon_wrap {
  max-width: 22px;
  min-width: 22px;
  &.active {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 2px;
      right: 2px;
      width: 8px;
      height: 8px;
      background-color: var(--pro-danger);
      border-radius: 50%;
    }
  }
}

.notification_item {
  .img_wrap {
    max-width: 53px;
    min-width: 53px;
    width: 100%;
    > div {
      border-radius: 4px;
      overflow: hidden;
    }
  }
  .content {
    > p {
      white-space: initial;
      font-size: 14px;
    }
    span {
      font-size: 10px;
      opacity: 0.6;
    }
  }
}
.notification_wrap {
  border-bottom: 1px solid var(--pro-outline-border);

  + p {
    font-size: 14px;
    cursor: pointer;
  }
}
.dropdown {
  --pro-dropdown-min-width: 252px;
  --pro-dropdown-link-hover-bg: transparent;
  --pro-dropdown-link-active-bg: transparent;
  --pro-dropdown-padding-x: 1rem;
  --pro-dropdown-item-padding-x: 0;
  --pro-dropdown-item-padding-y: 10px;
  border-radius: 10px;
}
.logout_btn {
  &:global(.pro-btn) {
    --pro-button-color: var(--pro-danger);
    --pro-button-hover-color: var(--pro-light);
    border: 1px solid var(--pro-danger);
    --pro-button-hover-bg: var(--pro-danger);
    &:active {
      background-color: var(--pro-danger);
      color: var(--pro-light);
    }
  }
}
.item_name {
  min-width: 105px;
  text-align: center;
}
.selectBox {
  :global {
    .pro-input__value-container .pro-input__single-value {
      min-width: 245px;
      max-width: 245px;
    }
    .pro-input__menu-list .pro-input__option {
      line-height: 1.3;
    }
  }
}
.user_wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid var(--pro-dropdown-border-color);
  padding-bottom: 15px;
  padding-top: 8px;
  .image_wrapper {
    width: 60px;
    display: flex;
    align-items: center;
    position: relative;
    height: 60px;
    img {
      width: 100%;
      height: 100%;
      padding: auto;
      left: 0;
      top: 0;
      // border-radius: 50%;
      overflow: hidden;
    }
  }
  .content_wrapper {
    width: calc(100% - 60px);
    display: flex;
    flex-direction: column;
    position: relative;
    padding-left: 20px;
    align-items: center;
    white-space: initial;

    .staff_name {
      font-size: 16px;
      line-height: 1.2;
      color: var(--pro-dark);
      text-decoration: none;
      font-weight: 500;
      width: 100%;
      margin-bottom: 5px;
    }
    .staff_email {
      font-size: 14px;
      line-height: 1.2;
      color: var(--pro-dark);
      text-decoration: none;
      font-weight: 400;
      width: 100%;
      opacity: 0.5;
      margin-bottom: 5px;
    }
    .staff_number {
      font-size: 14px;
      line-height: 1.2;
      color: var(--pro-dark);
      text-decoration: none;
      font-weight: 400;
      width: 100%;
      opacity: 0.5;
      margin-bottom: 0;
    }
  }
}

.logout {
  margin-left: 15px;
  width: 150px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
