.root {
    width: 100%;
    // overflow: hidden;
    // overflow-x: auto;
    position: sticky;
    top: 0;
    z-index: 5;
    border-bottom: 1px solid var(--pro-outline-border);
    height: 36px;
    background-color: var(--pro-secondary);
    ul {
      padding: 0;
      margin: 0;
      li {
        list-style: none;
        display: flex;
        min-width: 72px;
        overflow: hidden;
        &:first-child {
          & ~ li {
            margin-left: -1px;
          }
        }
        > button {
          max-width: 100%;
          background-color: transparent;
          border-radius: 0px;
          height: 36px;
          justify-content: flex-start;
          > span{
            position: relative;
            width: 100%;
            overflow: hidden;
            height: 18px;
          }
          :global {
            .pro-avatar {
              width: 18px;
              height: 18px;
              position: absolute;
              left: 0;
              top: 0;
              bottom: 0;
            }
          }
        }
        &.active {
          > button {
            background-color: var(--pro-light);
            border-bottom: 1px solid var(--pro-light);
            border-top: 1px solid var(--pro-light);
          }
        }
        :global(.pro-btn .pro-btn){
          width: 1.3333rem;
          height: 1.3333rem;
          line-height: 0.7;
          &:hover{
            background-color: transparent;
          }
        }
        p{
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          flex: 1;
          width: 100%;
          // padding-left: 26px;
          padding-left: 10px;
          padding-right: 10px;
          padding-bottom: 8px;
          padding-top: 8px;
        }
      }
    }
    .close{
      position: absolute;
      right: 4px;
      top: 0;
      width: 27px;
      height: 34px;
      display: flex;
      align-items: center;
    }
  }
  