.root{
    background-color: var(--pro-light);
    border-radius: 5px;
    margin-top: 10px;
    cursor: pointer;
    border: 2px solid;
    transition: border-color 0.25s ease;
    border-color: transparent;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
    &:hover{
        border-color: var(--pro-border-color);
    }
    &.active{
        border-color: var(--pro-primary);
    }
    .tick{
        width: 18px;
        height: 18px;
        border: 1px solid var(--pro-green);
        color: var(--pro-green);
        span.material-symbols-outlined{
            font-size: 14px !important;
        }
    }
    p + .card_footer{
        margin-top: 0.75rem;
    }
    .card_footer{
        align-items: center;
        gap: 0.5rem;
    }
    .order_badge_urgent{
        background-color: var(--pro-danger);
        border-radius: 3px;
        color: var(--pro-light);
        padding-block: 3px;
        font-size: 11px;
        gap: 7px;
    }
    .order_badge_normal{
        background-color: rgb(24, 163, 243);
        border-radius: 3px;
        color: var(--pro-light);
        padding-block: 3px;
        font-size: 11px;
        gap: 7px;
    }
    .order_badge_hold{
        background-color: #ffc400;
        border-radius: 3px;
        color: var(--pro-light);
        padding-block: 3px;
        font-size: 11px;
        gap: 7px;
    }
    .order_badge_green{
        background-color: #059c44;
        border-radius: 3px;
        color: var(--pro-light);
        padding-block: 3px;
        font-size: 11px;
        gap: 7px;
    }
    .number{
        color: #718797;
    }
    .title{
        font-size: 14px;
        word-break: break-word;
    }
}