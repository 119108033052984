.root {
  background-color: var(--pro-light);
  margin-top: 10px;
  .content {
    width: 60%;
  }
  .header{
    width: 80%;
  }
}
