.card_main_wrapper {
  .card_scroll_wrap{
    height: calc(100vh - 150px);
    overflow-x: auto;
    overflow-y: auto;
    padding-bottom: 10px;
    // scrollbar-width: thin;
    &::-webkit-scrollbar {
      cursor: pointer;
      height: 8px;
      width: 8px;
    }
  }
  .card_flow_wrap{
    min-height: 100%;
  }
}

.glicard_list {
  max-width: 200px;
  flex: 0 0 auto;
  padding-inline: 0;
  margin-right: 13px;
  >h6 {
    padding-inline: 0.45rem;
    position: sticky;
    top: 0;
    background-color: var(--pro-secondary);
  }
  > div {
    padding-inline: 0.45rem;
    // // overflow-y: auto;
    // // height: calc(100% - 40px);
    // scrollbar-width: thin;
    // &::-webkit-scrollbar-track {
    //   background-color: var(--pro-secondary);
    // }
    // &:not(:hover){
    //     &::-webkit-scrollbar-thumb{
    //         background-color: transparent;
    //     }
    // }
  }
}

.date_input {
  :global(.input-wrap .pro-input) {
    min-width: 100% !important;
  }
}

.modal_root{
  --pro-modal-width: 700px
}