.multicolumn_table {
  height: 100%;
  border: 1px solid var(--pro-outline-border);
  border-radius: 3px;
  padding: 1rem;
  &:not(.pro_table_wrapper) {
    table {
      width: 100%;
      tr {
        td {
          width: 50%;
          padding: 5px 0;
          & + td {
            padding-left: 5px;
          }
        }
      }
    }
  }
  &.pro_table_wrapper {
    table th {
      white-space: initial;
    }
  }
}
.multicolumn_wrapper {
  --pro-gutter-y: 10px;
  ~ .multicolumn_wrapper {
    margin-top: 0px;
  }
}

.card_main_wrapper {
  height: calc(100vh - 154px);
  .card_flow_wrap {
    height: 100%;
    overflow-x: auto;
    > div {
      min-width: 210px;
    }
  }
}

.table_main_wrapper {
  height: calc(100vh - 134px);
  position: relative;
  border-left: 1px solid var(--pro-outline-border);
  .table_flow_wrapper {
    overflow-y: auto;
    height: 100%;
    padding-right: 5px;
    padding-left: 10px;
    padding-bottom: 4.4rem;
  }
  .compare_inner_wrap {
    padding-left: 1rem;
    height: 100%;
  }
}
.footer_btn_wrap {
  position: absolute;
  bottom: 0;
  background-color: var(--pro-light);
  z-index: 5;
  border-top: 1px solid var(--pro-outline-border);
  width: calc(100% + 10px);
  right: -10px;
}

.filter_btn {
  height: 100%;
  align-items: center;
}

.pdf_container {
  position: fixed;
  opacity: 0;
  pointer-events: none;
  font-family : none;
}

.info_btn {
  padding: 5px;
  display: flex;
  cursor: pointer;
  border-radius: 50%;
  background-color: transparent;
  transition: background-color 0.25s;
  &:hover {
    background-color: var(--pro-border-color);
  }
}

.tooltip {
  background-color: var(--pro-light);
  color: var(--pro-dark);
  // border: 1px solid var(--pro-outline-border);
  box-shadow: 0 0 4px 1px rgb(9 30 66 / 13%);
  padding: 8px 14px;
  border-radius: 4px;
  z-index: 1050;
  * {
    border: 0;
  }
  td {
    padding: 2px 5px;
    max-width: 400px;
  }
}


.copy_text {
  padding: 5px;
  display: flex;
  cursor: pointer;
  border-radius: 50%;
  background-color: transparent;
  transition: background-color 0.25s;
  &:hover {
    background-color: var(--pro-border-color);
  }
}